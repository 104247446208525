import { AnswerItem } from '@dreamplan/types';
// This is an issue with the date-fns library https://github.com/date-fns/date-fns/issues/1677
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB, da, es, nb } from 'date-fns/locale';

class CalculationCountryError extends Error {
  constructor() {
    super('This calculation country does not have a supported currency.');
    this.name = 'CalculationCountryError';
  }
}

const CultureCodes = ['en', 'en-GB', 'da', 'da-DK', 'es', 'no'] as const;
export type TCultureCodes = (typeof CultureCodes)[number];

const SupportedCurrencies = ['DKK', 'GBP', 'EUR', 'NOK'] as const;
export type TSupportedCurrencies = (typeof SupportedCurrencies)[number];

export const DEFAULT_CULTURE_CODE: TCultureCodes = 'da';
const DEFAULT_CURRENCY: TSupportedCurrencies = 'DKK';

export function parseLocale(locale: string | null): TCultureCodes {
  if (locale && (CultureCodes as readonly string[]).includes(locale)) {
    return locale as TCultureCodes;
  }
  return DEFAULT_CULTURE_CODE;
}

export function parseSupportedCurrencyFromCalculationCountry(
  calculationCountry: string | null | undefined,
): TSupportedCurrencies {
  if (!calculationCountry) {
    return DEFAULT_CURRENCY;
  }

  switch (calculationCountry) {
    case 'DK': {
      return 'DKK' as TSupportedCurrencies;
    }
    case 'UK': {
      return 'GBP' as TSupportedCurrencies;
    }
    case 'ES': {
      return 'EUR' as TSupportedCurrencies;
    }
    case 'NO': {
      return 'NOK' as TSupportedCurrencies;
    }
    default: {
      throw new CalculationCountryError();
    }
  }
}

export function formatCurrency(
  value: number,
  cultureCode: TCultureCodes = DEFAULT_CULTURE_CODE,
  currency: TSupportedCurrencies = DEFAULT_CURRENCY,
  maxFractionDigits: number = 0,
  minFractionDigits: number = 0,
) {
  return new Intl.NumberFormat(cultureCode, {
    style: 'currency',
    currency,
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  }).format(value);
}

export function formatPercentage(
  value: number,
  cultureCode: TCultureCodes = DEFAULT_CULTURE_CODE,
  maxFractionDigits: number = 3,
  minFractionDigits: number = 0,
) {
  return new Intl.NumberFormat(cultureCode, {
    style: 'percent',
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  }).format(value / 100);
}

export function formatDecimal(
  value: number,
  cultureCode: TCultureCodes = DEFAULT_CULTURE_CODE,
  maxFractionDigits: number = 0,
  minFractionDigits: number = 0,
) {
  return new Intl.NumberFormat(cultureCode, {
    style: 'decimal',
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  }).format(value);
}

export const arrayToObject = (array: any[]) => {
  if (!array || array.length === 0) return {};
  return array.reduce((acc: any, item: any) => {
    return {
      ...acc,
      [item.key]: item.value,
    };
  }, {});
};

export const answerValue = (key: string, answers: AnswerItem[]) =>
  answers.find((a) => a.key === key)?.value;

/**
 * A simple tailwinds utility function to deduplicate and merge tailwinds classes,
 * supports very limited set of classes which includes:
 * - width, height, max-width, max-height, background, text
 *
 * eg:
 *  twMerge('w-10 h-10 bg-red-500', 'w-20 h-20 bg-blue-500') => 'w-20 h-20 bg-blue-500'
 */
export function twMerge(base: string, override?: string | undefined | null) {
  if (!override) return base;
  let className = base.trim().replace(/\s+/g, ' ');
  const availableOverrides = [
    // max-width
    /(\w{2,3}:)?max-w-[[\]\w]+/g,
    // max-height
    /(\w{2,3}:)?max-h-[[\]\w]+/g,
    // width
    /((\w{2,3}:)?w-[[\]\w]+)/g,
    // height
    /(\w{2,3}:)?h-[[\]\w]+/g,
    // background
    /(\w{2,3}:)?bg-[-\w]*/g,
    // text color
    /(\w{2,3}:)?text-[-\w]*/g,
  ];

  availableOverrides.forEach((overrideRegex) => {
    const overrideMatch = override.match(overrideRegex);

    if (overrideMatch) {
      className = className.replace(overrideRegex, '').trim();
    }
  });

  return `${className} ${override}`.replace(/\s+/g, ' ').trim();
}

export function isInternalUser(email: string | null | undefined) {
  const isDreamplanUser = email?.match(/@dreamplan\.io$/) != null;
  return isDreamplanUser;
}

export function removeNonDigitChars(value: string | number) {
  if (!value) return '';
  value = value.toString();
  value = value.replace(/[\D]/g, ''); //remove all non-digit chars

  return Number(value).toString();
}

export function removePercentage(value: string | number) {
  value = value.toString();
  const result = value.match(/-?\d{0,2}([,.])?\d{0,2}/g)?.filter((v) => v);
  value = result?.join('').replace(',', '.') ?? '';
  return value;
}

export const parseFnsLocale = (locale: string): Locale => {
  switch (locale) {
    case 'da':
      return da;
    case 'da-DK':
      return da;
    case 'en':
      return enGB;
    case 'en-GB':
      return enGB;
    case 'es':
      return es;
    case 'no':
      return nb;
    default:
      return da;
  }
};

/**
 * Format and localize a given date with the `date-fns` library
 * @param date Date to be formatted. Can be a date object, but it will be constructed with `Date()`, therefore valid strings will work too
 * @param fnsFormat Format from date-fns library. Consists of a string with a predefined pattern which will determine the formatting type (e.g. `"PPPPpp"` will result in `May 29, 1453, 12:00:00 AM`). Choose the desired pattern at https://date-fns.org/docs/format
 * @param locale Desired locale string. Will be parsed to date-fns locale with `parseFnsLocale()` from `packages/utils/src/index.ts`. Currently supported: `'da'`, `'da-DK'`, `'en'`, `'en-GB'`, `'es'`, `'no'` Add more to `parseFnsLocale()` to support new languages
 * @returns string representing the formatted date
 */
export function formatDate(date: string | Date, fnsFormat: string, locale: string): string {
  return format(new Date(date), fnsFormat, { locale: parseFnsLocale(locale) });
}

/**
 * Make an old-school Form POST request for services that do not support JS Fetch API
 * @param path endpoint or page that is being POSTed to
 * @param params key-value pairs of parameters that will be inserted as fields in the request
 */
export function formPost(path: string, params: Record<string, string>) {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

/**
 * Take a base64-encoded value and convert it to a different encoding
 * @param input base-64 encoded value
 * @param encoding (optional, default: 'utf-8') target encoding
 * @returns string value represented with the requested encoding
 */
export function decodeBase64(input: string, encoding?: BufferEncoding): string {
  return Buffer.from(input, 'base64').toString(encoding ?? 'utf-8');
}

// Generic sum function that can sum up an array of any type, given a selector function.
export function sum<T>(array: T[] | undefined, selector: (item: T) => number | undefined): number {
  if (!array) {
    return 0;
  }

  return array.reduce((acc, item) => acc + (selector(item) ?? 0), 0);
}

/**
 * Groups an array of items into an object based on a selector function.
 *
 * @param {T[]} data - The array of items to group.
 * @param {(item: T) => string} selector - Function to select the key for grouping.
 * @param {(item: T) => R} transformer - Function to transform each item.
 * @returns {Record<string, R[]>} An object where keys are group identifiers and values are arrays of transformed items.
 *
 * @example
 * const products = [
 *   { id: 1, category: 'electronics', name: 'Laptop' },
 *   { id: 2, category: 'electronics', name: 'Phone' },
 *   { id: 3, category: 'furniture', name: 'Chair' }
 * ];
 * const grouped = groupBy(products, p => p.category, p => p.name);
 * // Result: { electronics: ['Laptop', 'Phone'], furniture: ['Chair'] }
 */
export function groupBy<T, R>(
  data: T[],
  selector: (item: T) => string,
  transformer: (item: T) => R,
): Record<string, R[]> {
  return data.reduce((acc, item) => {
    const key = selector(item);
    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(transformer(item));

    return acc;
  }, {} as Record<string, R[]>);
}

/**
 * Maps an array to a new array, excluding null or undefined results.
 *
 * @example
 * const numbers = [1, 2, 3];
 * const results = mapNonNullable(numbers, n => (n > 1 ? n * 2 : null));
 * // Result: [4, 6]
 */
export function mapNonNullable<T, U>(arr: T[], fn: (x: T) => U | null): U[] {
  return arr.map(fn).filter((x): x is U => x != null);
}

export async function benchmark<T>(name: string, cb: Promise<T>): Promise<T> {
  const start = performance.now();
  const result = await cb;
  const end = performance.now();
  // eslint-disable-next-line no-console
  console.log(`${name} | Execution time: ${end - start} ms`);
  return result;
}

/**
 * djb2 hash function
 */
export function fastHash(str: string) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i);
  }

  return hash >>> 0;
}
